<template>
    <div class="proposal-upload">
        <div v-if="isLoading">
            <mercur-spinner></mercur-spinner>
        </div>
        <div v-else-if="!quoteTokenValid">
            <p>This token is not valid (anymore)</p>
        </div>
        <div class="container" v-else-if="quoteTokenValid">
            <div class="row">
                <div class="col-6">
                    <form @submit.prevent="uploadFile" :action="url" :disabled="!url">
                        <mercur-card>
                            <h2 class="font-weight-normal mt-2">Upload Your Quote Proposal in PDF</h2>
                            <p v-if="quoteExpiryDate">Please upload your proposal before: {{ quoteExpiryDate }}</p>
                            <label>PDF file</label>
                            <filedropper @uploadUpdate="uploadUpdate" :url="url"></filedropper>
                            <div v-if="isUploading" class="position-relative mt-2">
                                <mercur-progress-bar :indeterminate="true"></mercur-progress-bar>
                            </div>
                            <div v-if="objectName">
                                <div class="mt-4"><strong>Your file:</strong></div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span >{{originalFilename}}</span>
                                    <mercur-button class="btn btn-icon" title="Remove" @click.prevent="removeFile">
                                        <i class="fas fa-trash text-grey"></i>
                                    </mercur-button>
                                </div>
                            </div>
                            <div v-if="result && result.type === 'error'" class="result result--is-error">
                                <p><strong>Something went wrong</strong></p>
                                <span v-html="result.message"></span>
                            </div>
                            <div class="d-flex justify-content-between mt-4">
                                <mercur-button type="submit" class="btn btn-yellow" :disabled="!url || isSending || !objectName">Save Proposal</mercur-button>
                                <mercur-button class="btn btn-primary" @click="declineQuoteProposal" :disabled="isSending">Decline quote</mercur-button>
                            </div>
                        </mercur-card>
                    </form>
                </div>
                <div class="col-6">
                    <div v-if="quoteAttachments && quoteAttachments.length" class="attachments">
                        <mercur-card>
                            <h2 class="font-weight-normal mt-2">Additional attachments</h2>
                            <hr />
                            <table class="col-12 px-0">
                                <th><td>Filename</td></th>
                                <tr v-for="(attachment, key) in quoteAttachments" :key="key">
                                    <td class="filename">
                                        <span class="ml-1">{{attachment.originalFileName}}</span>
                                    </td>
                                    <td class="text-right">
                                        <mercur-button class="btn btn-icon py-0" @click="downloadAttachment(attachment)"><i class="fas fa-download text-grey"></i></mercur-button>
                                    </td>
                                </tr>
                            </table>
                        </mercur-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import Filedropper from '@/components/utils/Filedropper'

export default {
    name: 'QuoteProposalUpload',
    components: { Filedropper },

    data () {
        return {
            productFile: null,
            isLoading: false,
            isSending: false,
            isUploading: false,
            result: null,
            closeConfirmActive: false,
            quoteExpiryDate: null,
            url: CONFIG.API.ROUTES.QUOTES.PROPOSAL.UPLOAD.replace('{quoteToken}', this.$route.params.token),
            quoteTokenValid: false,
            objectName: null,
            originalFilename: null,
            quoteAttachments: null,
        }
    },

    methods: {
        declineQuoteProposal () {
            this.isSending = true
            const url = CONFIG.API.ROUTES.QUOTES.PROPOSAL.DECLINE.replace('{quoteToken}', this.$route.params.token)

            this.addJob(url)
            const payload = {
                quoteToken: this.$route.params.token,
            }
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `You successfully declined the quote`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Something went wrong. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.isSending = false
                this.finishJob(url)
            })
        },
        uploadFile () {
            this.isSending = true
            const url = CONFIG.API.ROUTES.QUOTES.PROPOSAL.ADD.replace('{quoteToken}', this.$route.params.token)

            this.addJob(url)
            const payload = {
                quoteToken: this.$route.params.token,
                objectName: this.objectName,
            }
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Your proposal was successfully uploaded! Redirecting to Supplier Central now.`,
                })

                setTimeout(() => {
                    this.$router.push('/')
                }, 2000)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.isSending = false
                this.finishJob(url)
            })
        },

        uploadUpdate (files) {
            this.isUploading = true
            if (files.percentCompleted === 100) {
                this.objectName = files.file.objectName
                this.isUploading = false
            }
            if (files.type === 'start') {
                this.originalFilename = files.file.originalFilename
            }
        },

        downloadAttachment (attachment) {
            const url = CONFIG.API.ROUTES.QUOTES.PROPOSAL.ATTACHMENT_DOWNLOAD.replace('{quoteToken}', this.$route.params.token).replace('{quoteAttachmentId}', attachment.quoteAttachmentId)
            const jobName = attachment.objectName
            this.$set(attachment, 'downloadPending', true)

            this.addJob(jobName)
            this.$store.dispatch('tasks/addTask', {
                title: `Download quote proposal csv`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: true,
                onComplete: data => {
                    this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.data.url)
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: `Download started.`,
                    })
                    return this.$api.post(url, attachment).catch(data => {
                        this.$root.$emit('notification:global', {
                            message: `Downloading failed. Please try again.`,
                            type: 'error',
                            errors: data,
                        })
                    }).finally(() => {
                        attachment.downloadPending = false
                        this.finishJob(jobName)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },

        clearFileInput () {
            this.objectName = ''
        },

        removeFile () {
            this.isSending = true
            const url = CONFIG.API.ROUTES.QUOTES.PROPOSAL.DELETE.replace('{quoteToken}', this.$route.params.token)

            this.addJob(url)
            const payload = {
                quoteToken: this.$route.params.token,
                objectName: this.objectName,
            }
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Your proposal was successfully deleted!`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.isSending = false
                this.objectName = null
                this.finishJob(url)
            })
        },
    },

    watch: {
        objectName (newValue) {
            if (!newValue) {
                return
            }
            this.result = null
        },
    },

    created () {
        // Validate
        const url = CONFIG.API.ROUTES.QUOTES.PROPOSAL.VALIDATE.replace('{quoteToken}', this.$route.params.token)
        this.addJob(url)
        this.isLoading = true
        this.$api.post(url, this.$route.params.token)
            .then(({ data }) => {
                this.quoteTokenValid = data.valid
                this.quoteExpiryDate = data.expirationDate
                this.quoteAttachments = data.quoteAttachments
            })
            .catch((err) => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.isLoading = false
                this.finishJob(url)
            })
    },
}
</script>

<style lang="scss" scoped>
    .proposal-upload {
        padding: 20px;
    }
    .filename {
        vertical-align: middle;
        span {
            max-width: 160px;
            overflow: hidden;
            white-space:nowrap;
            display: inline-block;
            text-overflow:ellipsis;
        }
    }

    .result {
        text-align: center;
        max-width: 340px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;

        &--is-error {
            font-size: 13px;
            line-height: 1.3em;
            color: red;
        }
    }
</style>
